import { Grid, ThemeProvider } from '@mui/material';
import { useContext, useEffect } from 'react';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { useGetKpis } from './services/individual-vessel-performance-kpi.service';
import { TEXT } from 'shared/constants/text';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import {
  StatusKpiCard,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { theme } from 'styles/theme';

export interface PerformanceKPIProps {
  vesselImo: string;
}

export const PerformanceKPIs = (props: PerformanceKPIProps) => {
  const { utcDateRange } = useContext(NavBarContext);
  const vesselImo = props.vesselImo;

  const { data, loading, error } = useGetKpis(utcDateRange, vesselImo);

  const FoulingResistancCardProps: StatusKpiCardProps = {
    title: 'Fouling Resistance',
    kpiData: data?.foulingResistance,
    units: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
  };

  const DeltaPowerCardProps: StatusKpiCardProps = {
    title: 'Excess Power',
    kpiData: data?.deltaPower,
    units: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
  };

  const DeltaSpeedCardProps: StatusKpiCardProps = {
    title: 'Speed Loss',
    kpiData: data?.deltaSpeed,
    units: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
  };

  const MainEngineSFOCCardProps: StatusKpiCardProps = {
    title: 'Main Engine SFOC',
    kpiData: data?.mesfoc,
    units: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
  };

  const DailyFuelPenaltyCardProps: StatusKpiCardProps = {
    title: 'Excess Fuel (Av. Daily)',
    kpiData: data?.deltaPowerDailyFuelPenalty,
    units: TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY,
  };

  const DailyCO2PenaltyCardProps: StatusKpiCardProps = {
    title: 'Excess CO2 (Av. Daily)',
    kpiData: data?.deltaPowerDailyCO2Penalty,
    units: TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY,
  };

  const TotalFuelPenaltyCardProps: StatusKpiCardProps = {
    title: 'Excess Fuel (Total)',
    kpiData: data?.deltaPowerDailyFuelPenaltySum,
    units: TEXT.UNIT_MEASUREMENT.METRIC_TONNES,
  };

  const TotalCO2PenaltyCardProps: StatusKpiCardProps = {
    title: 'Excess CO2 (Total)',
    kpiData: data?.deltaPowerDailyCO2PenaltySum,
    footerUnits: TEXT.FORMULAS.AER,
    units: TEXT.UNIT_MEASUREMENT.METRIC_TONNES,
  };

  const kpiData = [
    FoulingResistancCardProps,
    DeltaPowerCardProps,
    DeltaSpeedCardProps,
    MainEngineSFOCCardProps,
    DailyFuelPenaltyCardProps,
    DailyCO2PenaltyCardProps,
    TotalFuelPenaltyCardProps,
    TotalCO2PenaltyCardProps,
  ];

  const { setCardData } = useContext(ExcelExportContext);

  useEffect(() => {
    setCardData(kpiData);
  }, [loading]);

  if (loading) {
    return <KpiCardSkeleton kpiCards={kpiData} />;
  }

  if (error) {
    return <KpiCardError kpiCards={kpiData} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container columns={12} spacing={2} sx={{ marginTop: 1 }}>
        {kpiData.map((cardProps, _) => (
          <Grid item xs={12} smPlus={6} mdPlus={4} lg={3} key={cardProps.title}>
            <StatusKpiCard {...cardProps} />
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};
