import { ExportableSections } from 'shared/utils/valid-sections';

export const fleetPageTitles = {
  environmentalMonitorFleetOverview: 'Environmental Monitor: Fleet Overview',
  fleetKpis: 'Fleet KPIs',
  ciiRatings: 'Carbon Intensity Indicator (CII) Ratings Trend',
  utilizationAndOperationalProfilesBreakdown:
    'Utilization and Operational Profiles Breakdown',
  eeoi: 'Energy Efficiency Operational Indicator (EEOI) Trend',
  fleetBreakdownByVessels: 'Fleet Breakdown by Vessels',
  vesselEnvironmentalKPIsComparison: 'Vessel Environmental KPIs Comparison',
  imoOverview: 'IMO Overview',
  euOverview: 'EU Overview',
};

export const vesselPageTitles = {
  voyageLegsComparisonId: ExportableSections['EM-Voyage Legs Comparison'],
  environmentalMonitorVesselOverview: 'Environmental Monitor: Vessel Overview',
  vesselEnvironmentalKPIsComparison: 'Vessel Environmental KPIs Comparison',
  imoOverview: 'IMO Overview',
  euOverview: 'EU Overview',
  vesselKPIs: 'Vessel KPIs',
  ciiRatings: 'Vessel’s Carbon Intensity Indicator (CII) Trend',
  aer: 'Vessel’s Annual Efficiency Ratio (AER) Trend',
  vesselEUATrends: 'Vessel’s European Union Allowances (EUA) Liability Trend',
  voyageLegEmissionComparison: 'Vessel’s Voyage Legs by EU MRV Regulation',
  voyageLegsComparison: 'Voyage Legs Comparison',
};
