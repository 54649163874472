import { Status } from 'routes/environmental-monitor/models/gql/vessel-kpi-gql.model';
import { TEXT } from 'shared/constants/text';
import { KpiData } from './status-kpi-card.component';

export const MockStatusKpiCard = <div>Mocked Status KPI Card Component!</div>;

export const successKpiData: KpiData = {
  primaryValue: '1',
  secondaryValue: '2.0',
  tertiaryValue: '3.0',
  quaternaryValue: '-33.33%',
  status: Status.success,
};

export const warningKpiData: KpiData = {
  primaryValue: '1',
  secondaryValue: '2.0',
  tertiaryValue: '3.0',
  quaternaryValue: '-33.33%',
  status: Status.warning,
};

export const errorKpiData: KpiData = {
  primaryValue: '1',
  secondaryValue: '2.0',
  tertiaryValue: '3.0',
  quaternaryValue: '-33.33%',
  status: Status.error,
};

export const emptyKpiData: KpiData = {
  primaryValue: TEXT.ERROR_MESSAGES.EMPTY_DASHES,
  secondaryValue: '',
  tertiaryValue: '',
  quaternaryValue: '',
  status: Status.none,
};

export const primaryKpiData: KpiData = {
  primaryValue: '1',
  secondaryValue: '2.0',
  tertiaryValue: '3.0',
  quaternaryValue: '-33.33%',
  status: Status.primary,
};

export const unknownKpiData: KpiData = {
  primaryValue: '',
  secondaryValue: '',
  tertiaryValue: '',
  quaternaryValue: '',
  status: Status.unknown,
};
