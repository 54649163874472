import {
  NA_VALUE_INDICATOR,
  NO_VALUE_INDICATOR,
} from 'shared/components/datagrid/DataGrid.component';

export const formatFloat = (
  inputFloat: number | undefined,
  displayZero: boolean | undefined = true
): string => {
  if (!displayZero && inputFloat === 0) {
    return '---';
  }
  return (inputFloat ?? 0).toFixed(2);
};

export const formatFloatToNumber = (
  inputFloat: number | undefined,
  decimals = 2
): number => {
  return Number((inputFloat ?? 0).toFixed(decimals));
};

export const convertStringToNumber = (input: number): number => {
  return Number(input ?? 0);
};

export const stringToNumber = (input: string): number => {
  return Number(input ?? 0);
};

export const formatFloatToPercentage = (
  inputFloat: number | undefined,
  displayZero = true
): string => {
  if (!displayZero && inputFloat === 0) {
    return '---';
  }

  return `${(inputFloat ?? 0).toFixed(2)}%`;
};

export const formatIntToPercentage = (
  inputFloat: number | undefined,
  displayZero = true
): string => {
  if (!displayZero && inputFloat === 0) {
    return '---';
  }

  return `${(inputFloat ?? 0).toFixed(0)}%`;
};

export const isNumeric = (input: any) => {
  const result =
    input != null && input !== '' && !isNaN(Number(input.toString()));
  return result;
};

export const formatNumber = (input: any, noDecimals = 0) => {
  //TODO: for this release, we are removing anything related to N/A
  if (input === 'N/A') {
    // N/A needs to be hardcoded here
    return NA_VALUE_INDICATOR;
  }

  if (isNumeric(input)) {
    let value = Number(input).toLocaleString('en-us', {
      minimumFractionDigits: noDecimals ?? 0,
      maximumFractionDigits: noDecimals ?? 0,
    });
    // removes negative zero
    if (value !== null && Number(value) === 0) value = value.replace('-', '');
    return value;
  }
  if (input == null) return NO_VALUE_INDICATOR;

  return input;
};

// This function is used to format large numbers to k (thousands). Example: 10000 -> 10 k
export const formatLargeNumber = (
  input: any,
  breakpoint = 9999.99,
  noDecimals = 2
) => {
  if (isNumeric(input)) {
    input =
      Math.abs(input) > breakpoint
        ? (Math.sign(input) * (Math.abs(input) / 1000)).toFixed(noDecimals) +
          ' k'
        : formatNumber(input, 2);
  } else {
    input = NO_VALUE_INDICATOR;
  }
  return input;
};
