import { Box } from '@mui/system';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { theme } from 'styles/theme';
import { typography } from 'styles/typography';
import { formatNumber } from 'shared/utils/float-utils';
import MuiChartContainer from '../mui-chart/container.component';
import {
  AllSeriesType,
  ChartsItemContentProps,
  ChartsLegend,
  PieValueType,
} from '@mui/x-charts-pro';

export type PieChartValueOptions = {
  value: any;
  name: string;
  color?: string;
  unit?: string;
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: any;
  payload?: any;
}) => {
  if (active && payload && payload.length) {
    const value = Number(payload[0].value || 0).toFixed(0);
    const unit = payload[0]?.payload.unit;
    return (
      <Box
        sx={{ borderRadius: '4px' }}
        bgcolor={theme.colors.offWhite}
        px={1}
        color={theme.colors.darkBlue}
        fontSize={'small'}
      >
        <Box fontWeight='bold' component={'span'}>
          {payload[0].name}:{' '}
        </Box>
        {value} {unit ?? '%'}
      </Box>
    );
  }

  return null;
};

export const PieChartStraightAngle = ({
  data,
}: {
  data: PieChartValueOptions[];
}) => {
  return (
    <ResponsiveContainer minHeight={170}>
      <PieChart>
        <Pie
          stroke='none'
          data={data}
          width={190}
          cy={110}
          startAngle={180}
          endAngle={0}
          innerRadius={87}
          outerRadius={100}
          fill='#FF0000'
          dataKey='value'
          nameKey='name'
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Legend
          iconType='circle'
          wrapperStyle={{ fontSize: typography.fontSize['1'] }}
        />
        {<Tooltip content={<CustomTooltip />} />}
      </PieChart>
    </ResponsiveContainer>
  );
};

function customTooltip(
  props: ChartsItemContentProps<'pie'>,
  dataset: PieChartValueOptions[]
) {
  const index = props.itemData.dataIndex;
  const data = dataset[index];
  const label = data?.name ?? '';
  const unit = data?.unit ?? '';
  const value = formatNumber(data?.value) + unit;

  return (
    <Box>
      <Box component={'span'} fontWeight='bold'>
        {label}:
      </Box>
      <Box component={'span'} sx={{ pl: 2 }}>
        {value}
      </Box>
    </Box>
  );
}

export const PieChartStraightAngleMui = ({
  data,
}: {
  data: PieChartValueOptions[];
}) => {
  const seriesData: PieValueType[] = data.map((item, index) => {
    return {
      id: index,
      label: item.name,
      value: item.value,
      color: item.color ?? '',
    };
  });

  const series = {
    startAngle: -90,
    endAngle: 90,

    innerRadius: 87,
    outerRadius: 100,
    data: seriesData,
    type: 'pie',
    cy: 70,
  } as AllSeriesType<'pie'>;

  return (
    <MuiChartContainer
      height={170}
      series={[series]}
      tooltip={{ content: (props) => customTooltip(props, data) }}
    >
      <ChartsLegend
        direction='row'
        position={{ vertical: 'bottom', horizontal: 'middle' }}
        padding={0}
        itemMarkHeight={14}
        itemMarkWidth={14}
        labelStyle={{ fontSize: typography.fontSize['1'] }}
      />
    </MuiChartContainer>
  );
};
