import { Grid, Typography } from '@mui/material';
import {
  AllSeriesType,
  AxisConfig,
  ChartsItemContentProps,
  ChartsXAxisProps,
} from '@mui/x-charts-pro';

import { FuelConsumption, FuelUsage } from '_gql/graphql';
import React from 'react';
import MuiChartContainer from 'shared/components/mui-chart/container.component';
import { StatusKpiCardProps } from 'shared/components/status-kpi-card/status-kpi-card.component';
import { emptyKpiData } from 'shared/components/status-kpi-card/status-kpi-card.mock';
import { TEXT } from 'shared/constants/text';
import { formatNumber } from 'shared/utils/float-utils';

const fuelColors = ['#319DB4', '#8C85CF', '#FF598C', '#ffffff'];

const customTooltipTitle = (
  props: ChartsItemContentProps<any>,
  fuelData: FuelUsage[]
) => {
  const data = fuelData[props.itemData.dataIndex];
  const title = `${data?.classification} (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`;
  return (
    <Grid container sx={{ width: '150px' }}>
      <Grid item xs={10}>
        <Typography fontWeight={'bold'}>{title}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography align='right' fontWeight={'bold'}>
          {data?.total}
        </Typography>
      </Grid>
    </Grid>
  );
};

const customTooltipContent = (
  props: ChartsItemContentProps<any>,
  fuelData: FuelUsage[]
) => {
  const data = fuelData[props.itemData.dataIndex];
  return (
    <Grid container sx={{ width: '150px' }}>
      {data?.consumptions?.map((consumption: FuelConsumption) => (
        <React.Fragment key={consumption.name}>
          <Grid item xs={10}>
            {consumption.name}
          </Grid>
          <Grid item xs={2}>
            <Typography align='right'>
              {formatNumber(consumption.consumptionMt, 0)}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export const FuelUsageChart = (fuelData: FuelUsage[]): StatusKpiCardProps => {
  const dataSet = fuelData.map((item) => {
    return {
      classification: item.classification,
      total: item.total,
    };
  });

  const maxTotal = Math.max(...dataSet.map((item) => item.total ?? 0));
  const series = [{ dataKey: 'total', type: 'bar' }] as AllSeriesType<'bar'>[];

  // tooltip
  const tooltipTitle = (props: ChartsItemContentProps<any>) =>
    customTooltipTitle(props, fuelData);
  const tooltipContent = (props: ChartsItemContentProps<any>) =>
    customTooltipContent(props, fuelData);

  return {
    title: 'Fuel Usage (EU)',
    titleAlignment: 'center',
    hideFooterDivider: true,
    footerType: (
      <MuiChartContainer
        dataset={dataSet}
        series={series}
        height={170}
        margin={{ top: 15, right: 30, left: 30 }}
        tooltip={{
          title: tooltipTitle,
          content: tooltipContent,
        }}
        sectionVisibility={{
          highlight: false,
          legend: false,
          grid: false,
          markers: false,
          axisY: false,
        }}
        yAxis={[{ max: maxTotal }]}
        xAxis={[
          {
            dataKey: 'classification',
            scaleType: 'band',
            tickLabelStyle: { angle: -35 },
            categoryGapRatio: 0.6,
            colorMap: {
              type: 'ordinal',
              colors: fuelColors,
            },
          } as AxisConfig<'band', string, ChartsXAxisProps>,
        ]}
      ></MuiChartContainer>
    ),
    kpiData: emptyKpiData,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
  };
};
