import { UTCDate } from 'shared/utils/date-utc-helper';
import { theme } from 'styles/theme';
import {
  ChartsItemContentProps,
  ChartsReferenceLine,
  LineSeriesType,
} from '@mui/x-charts-pro';
import MuiChartContainer from 'shared/components/mui-chart/container.component';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { TEXT } from 'shared/constants/text';
import {
  ChartKey,
  ChartKeyItem,
  ChartKeyProps,
} from 'shared/components/chart-key/chart-key.component';
import { Box } from '@mui/system';
import { nameof } from 'shared/components/datagrid';
import { Grid } from '@mui/material';
import { formatNumber } from 'shared/utils/float-utils';

export type FuelEUGhgIntensityChartProps = {
  date: Date;
  intensity: number;
  intensityTarget: number;
  complianceBalance: number;
  fuelEUPenalty: number;
};

function customTooltip(
  props: ChartsItemContentProps<'line'>,
  chartDataset: FuelEUGhgIntensityChartProps[]
) {
  const { itemData } = props;

  // if the dataset or the itemData is not available, return null
  if (!chartDataset || itemData.dataIndex === undefined) return null;

  const data = chartDataset[itemData.dataIndex];

  return (
    <Grid container sx={{ width: '250px' }}>
      <Grid item xs={10}>
        GHG Intensity Target
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {formatNumber(data?.intensityTarget, 2)}
      </Grid>

      <Grid item xs={10}>
        GHG Intensity
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {formatNumber(data?.intensity, 2)}
      </Grid>

      <Grid item xs={10}>
        Compliance Balance
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        {formatNumber(data?.complianceBalance)}
      </Grid>

      <Grid item xs={10}>
        FuelEU Penalty
      </Grid>
      <Grid item xs={2} textAlign={'right'}>
        €{formatNumber(data?.fuelEUPenalty)}
      </Grid>
    </Grid>
  );
}

export function FleetFuelEUGhgIntensityChart() {
  const GhgIntensity: ChartKeyItem = {
    id: 1,
    name: 'GHG Intensity',
    fill: '#66BB6A',
  };
  const GhgIntensityTarget: ChartKeyItem = {
    id: 2,
    name: 'GHG Intensity Target',
    fill: '#8C85CF',
  };
  const keyProps: ChartKeyProps = { items: [GhgIntensity, GhgIntensityTarget] };
  const intensityTarget = 70;

  const y1 = [5, 5, 10, 90, 85, 70, 30, 25, 25];
  const chartDataset: FuelEUGhgIntensityChartProps[] = Array.from(
    { length: 9 },
    (_, index) => {
      return {
        date: new Date(2025, 1 + index, 1),
        intensity: y1[index] ?? 0, // Provide a default value of 0 for intensity
        intensityTarget: intensityTarget,
        complianceBalance: 20,
        fuelEUPenalty: 30,
      };
    }
  );

  const bands = [
    { y: 60, color: theme.colors?.eRating },
    { y: 20, color: theme.colors?.bRating },
    { y: 0, color: theme.colors?.aRating },
  ];

  const chartProps = nameof<FuelEUGhgIntensityChartProps>;
  const seriesDate: LineSeriesType[] = [
    {
      type: 'line',
      dataKey: chartProps('intensity'),
      label: GhgIntensity.name,
      highlightScope: { highlighted: 'item', faded: 'global' },
      color: GhgIntensity.fill,
    },
  ];

  const yearFormatter = (date: Date) => {
    return new UTCDate(date).formatMY();
  };

  const yAxisTitle = `GHG Intensity (${TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE})`;

  return (
    <CardContainer title={'FuelEU Greenhouse Gas (GHG) Intensity Trend'}>
      <Box sx={{ mt: 3, pl: 2 }}>
        <ChartKey items={keyProps.items} />
      </Box>
      <MuiChartContainer
        background={{ bands: bands }}
        dataset={chartDataset}
        series={seriesDate}
        tooltip={{
          content: (props) => customTooltip(props, chartDataset),
        }}
        xAxis={[
          {
            dataKey: chartProps('date'),
            tickNumber: chartDataset.length,
            scaleType: 'utc',
            tickLabelStyle: { angle: -35 },
            valueFormatter: yearFormatter,
          },
        ]}
        yAxis={[{ label: yAxisTitle }]}
      >
        <ChartsReferenceLine
          y={intensityTarget}
          label={GhgIntensityTarget.name}
          labelStyle={{ fontSize: '12px' }}
          lineStyle={{ stroke: '#8F80CF', strokeWidth: 2 }}
        />
      </MuiChartContainer>
    </CardContainer>
  );
}
