import { Box, Typography } from '@mui/material';

import {
  customFooterType,
  KpiCardDivider,
  KpiCardGridContainer,
  RenderKPIGroup,
  StatusKpiCardProps,
} from 'shared/components/status-kpi-card/status-kpi-card.component';
import { emptyKpiData } from 'shared/components/status-kpi-card/status-kpi-card.mock';
import { TEXT } from 'shared/constants/text';
import { toStatusEnum } from 'shared/utils/status-utils';
import { formatNumber } from 'shared/utils/float-utils';
import { DateRange } from 'shared/utils/date-utc-helper';
import React, { useContext } from 'react';
import { NavBarContext } from 'shared/models/navBar.context.model';
import { useGetFleetKpis } from 'routes/environmental-monitor/services/fleet.service';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { KpiCardError } from 'shared/components/error/kpi-card-error.component';
import { KpiCardSkeleton } from 'shared/components/kpi-card-skeleton/kpi-card-skeleton.component';

import { FuelUsageChart } from './fueleu-fuel-usage-chart.component';
import { typography } from 'styles/typography';
import { useGetFeatureFlag } from 'shared/services/featureFlag.service';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';
import { PieChartStraightAngle } from 'shared/components/piechart-straightangle/piechart';
import { VesselKPIDataMapper } from 'routes/environmental-monitor/mappers/vessel-kpi-data.mapper';
import { IFleetKpiDomain } from 'routes/environmental-monitor/models/fleet-kpi.model';

export type FleetEUKpiDataSummaryProps = {
  dateRange: DateRange | null;
};

export const FleetEUKpiDataSummary: React.FC<FleetEUKpiDataSummaryProps> = ({
  dateRange,
}) => {
  const fleetGhgKpiFeatureEnabled = useGetFeatureFlag(FEATURE_FLAG.MRV_PART_2)
    .data?.isEnabled;
  const { imoNumbers } = useContext(NavBarContext);

  const { data, loading, error } = useGetFleetKpis(dateRange, imoNumbers);
  const ghg = data?.kpis?.ghgMrv?.ghgByYear
    ?.map((v) => v.value)
    .reduce(function (a, b) {
      return a + b;
    });
  const liability = data?.kpis?.eua?.liability;
  const isNoReportsError = liability === -1;
  const planned = data?.kpis?.eua?.planned;
  const status = toStatusEnum(data?.kpis?.eua?.status ?? '');
  const isPlanned = planned && planned > 0;
  const liabilityPercent = data?.kpis?.eua?.value ?? undefined;

  const validLiabilityPercent =
    isPlanned && liabilityPercent ? liabilityPercent : NA_VALUE_INDICATOR;
  const validLiability =
    liability != null ? liability?.toFixed() : NA_VALUE_INDICATOR;
  const ghgMrvValue: IFleetKpiDomain | undefined = fleetGhgKpiFeatureEnabled
    ? {
        ghgMrvTotal: VesselKPIDataMapper.toGhgMrvTotalDomain(
          data?.kpis?.ghgMrv ?? undefined
        ),
        ghgMrvFuelEmissions: VesselKPIDataMapper.toGhgMrvFuelEmissions(
          data?.kpis?.ghgMrv ?? undefined
        ),
        ghgMrvFuelSlippageTotal: VesselKPIDataMapper.toGhgMrvFuelSlipDomain(
          data?.kpis?.ghgMrv ?? undefined
        ),
      }
    : undefined;

  const EUACardProps: StatusKpiCardProps = {
    title: 'EU Allowances Against Planned',
    sectionVisibility: { footer: true },
    footerType: 'planned',
    kpiData: {
      primaryValue:
        isNoReportsError === true
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : `${validLiabilityPercent}`, // EUA Val
      secondaryValue:
        isNoReportsError === true
          ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
          : validLiability, // Liability
      tertiaryValue: `${planned || 0}`, // Planned
      quaternaryValue: '', // Deviation
      status: isPlanned && liabilityPercent ? status : 'unknown',
      unit: TEXT.UNIT_MEASUREMENT.PERCENTAGE,
    },
  };

  const passedCardProps: StatusKpiCardProps = {
    title: 'EU Allowances',
    kpiData: {
      status: liability ? 'graph' : 'unknown',
      primaryValue: formatNumber(liability),
    },
  };

  const GHGIntensityPropsDummyData: StatusKpiCardProps = {
    title: 'GHG Intensity',
    sectionVisibility: { footer: true },
    units: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ_PER_MEGAJOULE,
    footerType: [
      {
        title: 'Compliance Balance',
        value: formatNumber(data?.kpis?.fuelEU?.complianceBalance),
        unitLabel: TEXT.UNIT_MEASUREMENT.GRAMS_CO2_EQ,
      },
      {
        title: 'Fuel EU Penalty',
        value:
          TEXT.CURRENCY.EURO +
          ' ' +
          formatNumber(data?.kpis?.fuelEU?.fuelPenalty),
        unitLabel: '',
      },
    ] as customFooterType,
    kpiData: {
      primaryValue: formatNumber(data?.kpis?.fuelEU?.ghgIntensity, 2),
      status: toStatusEnum(data?.kpis?.fuelEU?.ghgIntensityStatus),
    },
  };

  const WellToWakeCardProps: StatusKpiCardProps = {
    title: 'Well to Wake (WtW) Summary\n(gCO₂eq/MJ)',
    titleAlignment: 'center',
    footerType: [
      {
        title: 'Well to Tank',
        value: formatNumber(data?.kpis?.fuelEU?.wellToTank, 2),
        unitLabel: '',
      },
      {
        title: 'Tank to Wake',
        value: formatNumber(data?.kpis?.fuelEU?.tankToWake, 2),
        unitLabel: '',
      },
      {
        title: 'Wind Reward Factor',
        value: formatNumber(data?.kpis?.fuelEU?.windRewardFactor, 2),
        unitLabel: '',
      },
    ] as customFooterType,
    kpiData: emptyKpiData,
    footerUnits: TEXT.FORMULAS.EEOI,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
  };

  const GhgMrvEmissionProps: StatusKpiCardProps = {
    title: 'Greenhouse Gas (GHG) Emission',
    titleAlignment: 'center',
    footerType: (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Typography
          sx={{ fontSize: typography.fontSize['10'], lineHeight: '100%' }}
        >
          {formatNumber(ghg, 2)}
        </Typography>
        <Box fontSize={'medium'} sx={{ color: 'gray' }}>
          tCO₂eq
        </Box>
        <Box sx={{ mt: 3 }}>MRV, Tank to Wake</Box>
      </Box>
    ),
    kpiData: emptyKpiData,
    hideFooterDivider: true,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
    hideHeaderDivider: true,
  };

  const GhgMrvSummaryProps: StatusKpiCardProps = {
    title: 'GHGmrv Summary',
    titleAlignment: 'center',
    footerType: (
      <PieChartStraightAngle
        data={VesselKPIDataMapper.toGhgMrvSummaryChartDomain(ghgMrvValue)}
      ></PieChartStraightAngle>
    ),
    kpiData: emptyKpiData,
    hideFooterDivider: true,
    sectionVisibility: {
      primaryValue: false,
      footer: true,
    },
    hideHeaderDivider: true,
  };

  const euKPIData = [isPlanned ? EUACardProps : passedCardProps];
  const GHGKPIData = fleetGhgKpiFeatureEnabled
    ? [GhgMrvEmissionProps, GhgMrvSummaryProps]
    : [];
  const fuelEUKPIData = [
    GHGIntensityPropsDummyData,
    WellToWakeCardProps,
    FuelUsageChart(data?.kpis?.fuelEU?.fuelUsage ?? []),
  ];

  if (error) {
    return (
      <>
        <KpiCardError
          kpiCards={[
            {
              title: 'EUA',
              showVerticalDivider: true,
              showBoxContainerTitle: true,
              boxContainerTitleText: 'EU ETS',
            },
            {
              title: 'GHG Emission',
              showVerticalDivider: false,
              showBoxContainerTitle: true,
              boxContainerTitleText: 'EU MRV',
            },
            {
              title: 'GHG Summary',
              showVerticalDivider: false,
            },
          ]}
        />
        <KpiCardError
          kpiCards={[
            {
              title: 'GHG Intensity',
              showVerticalDivider: false,
              showBoxContainerTitle: true,
              boxContainerTitleText: 'FUEL EU',
            },
            {
              title: 'Well to Wake Summary',
            },
            {
              title: 'Fuel Usage',
            },
          ]}
        />
      </>
    );
  }

  if (loading || data === undefined)
    return (
      <KpiCardSkeleton
        kpiCards={[
          { sectionVisibility: { footer: true } },
          { sectionVisibility: { footer: true } },
          { sectionVisibility: { footer: true } },
        ]}
      />
    );

  return (
    <>
      <KpiCardGridContainer>
        {/* EU ETS Kpis  */}
        <RenderKPIGroup title={'EU ETS'} kpiData={euKPIData} />

        <KpiCardDivider />

        {/* GHG Kpis  */}
        <RenderKPIGroup title={'EU MRV'} kpiData={GHGKPIData} />
      </KpiCardGridContainer>

      {/* Fuel EU Kpis  */}
      <KpiCardGridContainer>
        <RenderKPIGroup title={'FUEL EU'} kpiData={fuelEUKPIData} />
      </KpiCardGridContainer>
    </>
  );
};
