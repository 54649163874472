import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
  Divider,
  Grid,
  SxProps,
} from '@mui/material';
import { DataGridTable } from 'shared/components/datagrid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';
import { useContext, useRef, useState } from 'react';
import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';
import { getColumnsDefinition } from './plannedItinerary-sequence.config';

import {
  EmptyOption,
  ValidatedTextField,
} from 'shared/components/inputs/input-fields';
import {
  ISequence,
  IPlannedItinerary,
  IPlannedItineraryResults,
} from '../../models/plannedItinerary.model';
import { theme } from 'styles/theme';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { sectionContext } from 'shared/components/section/section.component';
import { PortSelectionInput } from './port-selection/port-selection.component';
import { EMPTY_PORT_DATA, PortData } from 'shared/utils/portpolygons-util';
import { SequenceRulesValidator } from './sequence-rules/sequence-rules-validator';
import { SequenceValidationResult } from './sequence-rules/sequence-rule';
import { WarningMessage } from 'shared/components/warning-message/warning-message';

const emptyRecord: ISequence = { id: 1, sequence: EmptyOption.Undefined };
const _data: ISequence[] = [{ ...emptyRecord }];

export type onSequenceCallBack = (newData: IPlannedItinerary) => void;

const styleButtonsContainer: SxProps = {
  my: 2,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 1.5,
  position: 'absolute',
  width: '100%',
  bottom: 0,
  zIndex: 10,
  backgroundColor: theme.background.component,
  right: 0,
  px: '24px',
  py: '12px',
  margin: 0,
  borderTop: '1px solid rgba(255, 255, 255, 0.09)',
};

const StyleButton = (props: ButtonProps) => (
  <Button
    {...props}
    variant='contained'
    disableElevation
    disableRipple
    sx={{
      textTransform: 'none',
      minWidth: '80px',
      '&.Mui-disabled': {
        backgroundColor: theme.palette.text.disabled,
      },
      height: '100%',
    }}
  />
);

const StyleCircularProgress = (props: CircularProgressProps) => (
  <CircularProgress
    size={20}
    {...props}
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-12px',
      marginLeft: '-12px',
    }}
  />
);

export function PlannedItinerarySequence({
  onCompare,
  onCalculate,
  onSave,
  onClose,
  compareData,
  itineraryData,
  isLoading,
}: Readonly<{
  onCalculate: onSequenceCallBack;
  onCompare: onSequenceCallBack;
  onSave?: onSequenceCallBack;
  onClose?: () => void;
  compareData?: IPlannedItineraryResults[];
  itineraryData?: IPlannedItinerary | undefined;
  isLoading?: boolean;
}>) {
  const [itineraryName, setItineraryName] = useState<string>(
    itineraryData?.itineraryName ?? ''
  );
  const [lastPortOfCall, setLastPortOfCall] = useState<PortData>(
    itineraryData?.lastPortOfCall ?? EMPTY_PORT_DATA
  );

  const { setAdditionalIntroductoryRowsData } = useContext(ExcelExportContext);
  const section = useContext(sectionContext);

  const [data, setData] = useState<ISequence[]>(
    itineraryData?.sequences ?? _data
  );

  const apiRef = useGridApiRef();
  const gridInputData = () => apiRef.current?.getSortedRows() as ISequence[];

  const handleDataChange = () => {
    const gridData = gridInputData();
    setData(gridData);
    const plannedItinerary: IPlannedItinerary = {
      id: itineraryData?.id,
      itineraryName: itineraryName,
      lastPortOfCall: lastPortOfCall,
      sequences: gridData,
    };

    // Call parent function to update the data
    onCalculate(plannedItinerary);
  };

  const resetSequences = () => {
    updateGridData([emptyRecord]);
    const plannedItinerary: IPlannedItinerary = {
      id: itineraryData?.id,
      itineraryName: itineraryName,
      sequences: [],
    };

    // Call parent function to update the data
    onCalculate(plannedItinerary);
    setDisableCompare(true);
    setDisableSave(true);
  };

  const updateGridData = (newData: ISequence[]) => {
    newData.forEach((row, ix) => {
      row.id = ix + 1;
    });
    setData(newData);
  };

  const addNewSequence = () => {
    const gridData = gridInputData();
    updateGridData([...gridData, emptyRecord]);
  };

  const handleDeleteClick = (id: number | string) => {
    const gridData = gridInputData();
    const newData = gridData.filter((row) => row.id !== id);

    if (newData.length === 0) {
      newData.push(emptyRecord);
    }

    updateGridData(newData);
  };

  const [disableCompare, setDisableCompare] = useState(true);
  const [disableSave, setDisableSave] = useState(true);

  const handleCompareClick = () => {
    const gridData = gridInputData();
    setData(gridData);
    const plannedItinerary: IPlannedItinerary = {
      id: itineraryData?.id ?? 0,
      itineraryName: itineraryName,
      lastPortOfCall: lastPortOfCall,
      sequences: gridData,
    };

    // Call parent function to update the data
    onCompare(plannedItinerary);
    if (onClose) onClose();

    // Reset the compare button and itinerary name
    setDisableCompare(true);
    setItineraryName('');
  };

  const handleSaveClick = () => {
    const gridData = gridInputData();
    setData(gridData);
    const plannedItinerary: IPlannedItinerary = {
      id: itineraryData?.id ?? 0,
      itineraryName: itineraryName,
      lastPortOfCall: lastPortOfCall,
      sequences: gridData,
    };

    // Call parent function to update the data
    const newData = { ...plannedItinerary };
    if (onSave) onSave(newData);
    if (onClose) onClose();
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  // this is used to update the additional introductory rows in the excel export
  const updateAdditionalIntroductoryRowsData = (props: {
    itineraryName: string;
    portName: string;
  }) => {
    setAdditionalIntroductoryRowsData?.(section, [
      ['Itinerary Name', props.itineraryName],
      ['Last Port of Call', props.portName],
    ]);
  };

  const handleItineraryNameChange = (value: string) => {
    const gridData = gridInputData();
    setData(gridData);
    setItineraryName(value);
    if (formValid.current.name === false) setDisableCompare(true);
    updateAdditionalIntroductoryRowsData({
      itineraryName: value,
      portName: lastPortOfCall.Port_Name,
    });
  };

  const handleLastPortOfCallChange = (value: PortData) => {
    const gridData = gridInputData();
    setData(gridData);
    setLastPortOfCall(value);
    updateAdditionalIntroductoryRowsData({
      itineraryName: itineraryName,
      portName: value.Port_Name,
    });
  };

  const [itineraryError, setItineraryError] = useState(
    SequenceValidationResult.Passed
  );

  const flagInvalidSequence = (
    gridData: ISequence[],
    result: SequenceValidationResult
  ) => {
    const row = gridData.filter((r) => r.id === result.id)[0];
    if (!result.valid && row) {
      row.sequenceFailingField = result.failingField;
    }
  };

  const handleCalculate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const gridData = gridInputData();
    gridData.forEach((row) => (row.sequenceFailingField = undefined));
    setData(gridData);

    const result = SequenceRulesValidator.Validate(gridData);
    setItineraryError(result);

    if (
      e.currentTarget.checkValidity() &&
      formValid.current.name &&
      result.valid
    ) {
      // form is valid
      setDisableCompare(false);
      setDisableSave(false);
      handleDataChange();
    } else {
      // form is invalid
      setDisableCompare(true);
      setDisableSave(true);
      flagInvalidSequence(gridData, result);
    }
  };

  const existingNames =
    compareData
      ?.filter((x) => x.itineraryName !== itineraryData?.itineraryName)
      .map((data) => data.itineraryName) ?? [];

  const nameValidator = (value: string) => {
    if (value.length < 3) return 'Name must be at least 3 characters long';
    if (value.length > 20) return 'Name must be less than 20 characters long';
    if (!/^[a-zA-Z0-9- ]+$/.test(value))
      return 'Name must contain only letters, numbers, spaces or "-"';
    if (existingNames.includes(value)) return 'Name already exists';
    return false;
  };
  const formValid = useRef({ name: itineraryName !== '' });

  return (
    <CardContainer title={''}>
      <Box component='form' onSubmit={handleCalculate} noValidate>
        <Box sx={{ mx: 2, mt: 0, display: 'flex', gap: 2 }}>
          <ValidatedTextField
            label={'Itinerary Name'}
            data-testid='Itinerary-Name'
            value={itineraryName}
            variant='filled'
            fullWidth={false}
            required
            onChange={(e) => handleItineraryNameChange(e.target.value)}
            validator={nameValidator}
            formCheck={(isValid) => (formValid.current.name = isValid)}
          ></ValidatedTextField>
          <PortSelectionInput
            onMenuChange={(newValue: PortData) =>
              handleLastPortOfCallChange(newValue)
            }
            label={'Last Port of Call'}
            required
            value={lastPortOfCall}
            sx={{ minWidth: '200px' }}
          />
          {!itineraryError.valid && (
            <WarningMessage
              sx={{ my: '-3px' }}
              title='Problem with itinerary operations.'
              details={itineraryError.message}
            />
          )}
        </Box>
        <Box sx={{ mx: 2, mt: 1 }}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box sx={{ my: 3 }}>
              Enter values below to forecast the planned CII summary.
            </Box>
            <SecondaryButton
              variant='outlined'
              sx={{ mr: 1.5 }}
              onClick={addNewSequence}
            >
              {'+ Add Sequence'}
            </SecondaryButton>
          </Grid>

          <DataGridTable
            apiRef={apiRef}
            name='sequence'
            rows={data}
            rowStyle='rounded'
            columns={getColumnsDefinition(handleDeleteClick)}
            getRowHeight={() => 'auto'}
            columnHeaderHeight={0}
          />

          <Box component={'article'} sx={styleButtonsContainer}>
            <Box sx={{ position: 'relative' }}>
              <StyleButton
                onClick={() => resetSequences()}
                disabled={isLoading ?? false}
              >
                Reset
              </StyleButton>
              {isLoading && <StyleCircularProgress />}
            </Box>
            <Box sx={{ position: 'relative' }}>
              <StyleButton type='submit' disabled={isLoading ?? false}>
                Calculate{' '}
              </StyleButton>
              {isLoading && <StyleCircularProgress />}
            </Box>
            <Divider orientation='vertical' flexItem />
            <SecondaryButton
              onClick={handleClose}
              variant='outlined'
              sx={{ minWidth: '80px' }}
            >
              Cancel
            </SecondaryButton>

            {itineraryData?.id === undefined ? (
              <Box sx={{ position: 'relative' }}>
                <StyleButton
                  onClick={handleCompareClick}
                  disabled={disableCompare || (isLoading ?? false)}
                >
                  Add to Compare
                </StyleButton>
                {isLoading && <StyleCircularProgress />}
              </Box>
            ) : (
              <Box sx={{ position: 'relative' }}>
                <StyleButton
                  onClick={handleSaveClick}
                  disabled={disableSave || (isLoading ?? false)}
                >
                  Save
                </StyleButton>
                {isLoading && <StyleCircularProgress />}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </CardContainer>
  );
}
