import { useDrawingArea, useXScale, useYScale } from '@mui/x-charts-pro';
import { ScaleLinear } from 'd3-scale';
import { theme } from 'styles/theme';

export const AXIS_BANDS_ID = 'x-axis-bands';

export type BandData = {
  y: number;
  color: string;
};

export function DrawBackground(
  props: Readonly<{
    offsetX: React.MutableRefObject<number>;
    bands?: Array<BandData>;
  }>
) {
  const xAxisScale = useXScale(AXIS_BANDS_ID) as ScaleLinear<any, any>;
  const offset = xAxisScale.invert(-10) - xAxisScale.invert(0);
  props.offsetX.current = offset;
  return <DrawBands items={props.bands ?? []} />;
}

const getDeltas = (arr: Array<number>) => {
  if (arr.length < 2) return [];
  return arr.slice(1).map((val, i) => val - (arr[i] ?? 0));
};

const DrawBands = ({ items }: { items: BandData[] }) => {
  const { left, top, width, height } = useDrawingArea();
  const yAxisScale = useYScale() as ScaleLinear<any, any>;
  const yOrigins = [top, ...items.map((item) => yAxisScale(item.y) ?? 0)];
  const heights = getDeltas(yOrigins);

  return (
    <>
      {/* Draw the horizontal bands  */}
      {items.map((item, ix) => {
        const yOrigin = yOrigins[ix];
        const height = heights[ix];
        return (
          <rect
            key={item.y}
            x={left}
            y={yOrigin}
            width={width}
            height={height}
            style={{ fill: item.color, stroke: 'none' }}
            data-testid={`band-${ix}`} // Add test id for testing
          />
        );
      })}

      {/* Create the horizontal gradient effect */}
      <linearGradient id='gradient-bg'>
        <stop offset='0%' stopColor='#222' stopOpacity={0.7} />
        <stop
          offset='33%'
          stopColor={theme.background.component}
          stopOpacity={1}
        />
        <stop
          offset='66%'
          stopColor={theme.background.component}
          stopOpacity={1}
        />
        <stop offset='100%' stopColor='#222' stopOpacity={0.7} />
      </linearGradient>
      <rect
        key={'black'}
        x={left + 10}
        y={top}
        width={width - 20}
        height={height}
        style={{ fill: 'url(#gradient-bg)', stroke: 'none' }}
        data-testid='gradient-bg' // Add test id for the gradient background
      />
    </>
  );
};
