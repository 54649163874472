import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import {
  ChartsGrid,
  ChartsItemContentProps,
  ChartsTooltipSlots,
  PiePlot,
} from '@mui/x-charts-pro';
import { ChartContainer } from '../container.component';
import {
  StyledChartContainer,
  StyledChartsTooltip,
} from '../styles/ThemeProperties';

const CustomItemTooltipContent = (
  props: ChartsItemContentProps<'pie'>, // properties passed to the tooltip
  container?: Readonly<ChartContainer>
) => {
  const title = container?.tooltip?.title;

  const content = container?.tooltip?.content;
  const contentValue =
    (typeof content === 'function' ? content(props) : content) ?? '';

  const titleBox =
    typeof title === 'string' ? (
      <Box
        component={'header'}
        sx={{ py: 1, px: 3, borderBottom: '1px solid gray' }}
      >
        {title}
      </Box>
    ) : null;

  return (
    <Paper elevation={3} sx={{ backgroundColor: 'white', color: 'black' }}>
      {titleBox}

      <Box component={'section'} sx={{ px: 2, py: 1 }}>
        {contentValue}
      </Box>
    </Paper>
  );
};

// get the tooltip behavior
// with the tooltip prop undefined it will show the default Mui Chart Tooltip
const getTooltipSlots = (props: ChartContainer): ChartsTooltipSlots<any> => {
  if (props.tooltip === undefined) return {};

  // check if there is any custom tooltip option
  if (props.tooltip.content === undefined && props.tooltip?.title === undefined)
    return {};

  return {
    itemContent: (x: ChartsItemContentProps<any>) =>
      CustomItemTooltipContent(x, props),
  };
};

export default function MuiChartContainerWithNoAxis(
  props: Readonly<ChartContainer>
) {
  const tooltipSlots = getTooltipSlots(props);

  return (
    <StyledChartContainer height={400} {...props}>
      {props.children}
      <PiePlot />

      <StyledChartsTooltip trigger='item' slots={tooltipSlots} />

      <ChartsGrid horizontal />
    </StyledChartContainer>
  );
}
