import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { ResponsiveChartContainerProps } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import {
  ChartsAxisHighlight,
  ChartsGrid,
  ChartsXAxis,
  ChartsYAxis,
  ScatterPlot,
  ChartsXAxisProps,
  ScaleName,
  AxisConfig,
  ChartsYAxisProps,
  ChartsItemContentProps,
  ChartsTooltipSlots,
} from '@mui/x-charts-pro';

import { AxisValueFormatterContext } from '@mui/x-charts/models/axis';

import { useRef } from 'react';
import { MakeOptional } from '_gql/graphql';
import {
  AXIS_BANDS_ID,
  BandData,
  DrawBackground,
} from './background/draw-background.component';
import { typography } from 'styles/typography';
import {
  StyledChartContainer,
  StyledChartsTooltip,
} from './styles/ThemeProperties';
import MuiChartContainerWithNoAxis from './components/pie-chart.component';
import { Typography } from '@mui/material';

type AxisXConfig = AxisConfig<ScaleName, any, ChartsXAxisProps>;
type AxisYConfig = AxisConfig<ScaleName, any, ChartsYAxisProps>;
type AxisXConfigNoId = MakeOptional<AxisXConfig, 'id'>;
type AxisYConfigNoId = MakeOptional<AxisYConfig, 'id'>;

type tooltipContent =
  | string
  | React.ReactNode
  | ((params: ChartsItemContentProps<any>) => React.ReactNode);

export type ChartContainer = ResponsiveChartContainerProps & {
  background?: {
    bands?: BandData[];
  };
  children?: React.ReactNode | React.ReactNode[];
  tooltip?: {
    title?: tooltipContent;
    content?: tooltipContent;
  };
  sectionVisibility?: {
    axisX?: boolean;
    axisY?: boolean;
    grid?: boolean;
    legend?: boolean;
    highlight?: boolean;
    markers?: boolean;
  };
};

const getTooltipContentValue = (
  tooltip: tooltipContent,
  props: ChartsItemContentProps<any>
) => {
  if (typeof tooltip === 'string') {
    return tooltip;
  }

  if (typeof tooltip === 'function') {
    return tooltip(props);
  }
  return null;
};

const CustomItemTooltipContent = (
  props: ChartsItemContentProps<any>, // properties passed to the tooltip
  container?: Readonly<ChartContainer>
) => {
  const { itemData, series } = props;
  const dataset = container?.dataset;
  const axisX = container?.xAxis?.at(0);
  const axisFormatter = axisX?.valueFormatter;

  // if the dataset or the itemData is not available, return null
  if (
    (dataset === undefined || itemData.dataIndex === undefined) &&
    axisX?.data === undefined
  )
    return null;

  const axisData =
    axisX?.data ?? dataset?.map((d) => d[axisX?.dataKey ?? '']) ?? [];
  const tickValue = axisData[itemData.dataIndex];

  const title = container?.tooltip?.title;
  const titleValue =
    getTooltipContentValue(title, props) ??
    axisFormatter?.(tickValue, { location: 'tick' }).toString();

  const content = container?.tooltip?.content;
  const contentValue =
    getTooltipContentValue(content, props) ?? series.data[itemData.dataIndex];

  return (
    <Paper elevation={3} sx={{ backgroundColor: 'white', color: 'black' }}>
      <Typography
        fontWeight={'bold'}
        component={'header'}
        sx={{ py: 1, px: 3, borderBottom: '1px solid gray' }}
      >
        {titleValue}
      </Typography>
      <Box component={'section'} sx={{ px: 3, py: 1 }}>
        {contentValue}
      </Box>
    </Paper>
  );
};

const getMinMaxNumeric = (value: number | Date | undefined) => {
  if (!value) return undefined;
  return typeof value !== 'number' ? new Date(value).getTime() : value;
};

const getXAxisArray = (props: ChartContainer, offset: number) => {
  const dataset = props.dataset;
  const xAxisArray =
    props.xAxis?.map((x, ix) => {
      const axisData = x.data ?? dataset?.map((d) => d[x?.dataKey ?? '']) ?? [];
      const axisMin = getMinMaxNumeric(x.min) ?? Math.min(...axisData);
      const axisMax = getMinMaxNumeric(x.max) ?? Math.max(...axisData);

      const minX = axisMin + offset;
      const maxX = axisMax - offset;
      const angle = x.tickLabelStyle?.angle ?? 0;

      const maxAxisLabelLength = getAxisLabelsMaxLength(x, axisData);
      const tickLabelsY =
        15 + maxAxisLabelLength * Math.abs(Math.sin((Math.PI * angle) / 180));

      const axis: AxisXConfig = {
        ...x,
        id: x.id ?? `x-axis-${ix}`,
        min: minX,
        max: maxX,
        tickLabelStyle: {
          ...x.tickLabelStyle,
          translate: `0 ${tickLabelsY}px`,
          fontSize: typography.fontSize['1'],
        },
      };
      return axis;
    }) ?? [];

  return xAxisArray;
};

const getYAxisArray = (props: ChartContainer) => {
  const labelXposition = -25;
  return (
    props.yAxis?.map((y, ix) => {
      const axis: AxisConfig<ScaleName, any, ChartsYAxisProps> = {
        ...y,
        id: y.id ?? `y-axis-${ix}`,
        labelStyle: {
          fontSize: typography.fontSize['1'],
          translate: `${labelXposition}px`,
        },
        tickLabelStyle: {
          ...y?.tickLabelStyle,
          translate: '-5px 0',
          transformBox: 'fill-box',
          transformOrigin: 'center',
        },
      };

      return axis;
    }) ?? []
  );
};

// calculate the max length of the Axis values
const getAxisLabelsMaxLength = (
  axis: AxisXConfigNoId | AxisYConfigNoId | undefined,
  data?: any[]
) => {
  if (!data) return 0;
  const context: AxisValueFormatterContext = { location: 'tick' };
  const tickLabels =
    data?.map((d) => axis?.valueFormatter?.(d, context) ?? d) ?? [];
  const tickLabelsLength = tickLabels.map((d) => d?.length ?? 0);
  const maxLabelLength = Math.max(...tickLabelsLength);
  return maxLabelLength;
};

// get the tooltip behavior
// with the tooltip prop undefined it will show the default Mui Chart Tooltip
const getTooltipSlots = (props: ChartContainer): ChartsTooltipSlots<any> => {
  if (props.tooltip === undefined) return {};

  // check if there is any custom tooltip option
  if (props.tooltip.content === undefined && props.tooltip?.title === undefined)
    return {};

  return {
    itemContent: (x: ChartsItemContentProps<any>) =>
      CustomItemTooltipContent(x, props),
  };
};

export default function MuiChartContainer(props: Readonly<ChartContainer>) {
  const firstSeries = props.series?.at(0);
  if (firstSeries?.type === 'pie') {
    return <MuiChartContainerWithNoAxis {...props} />;
  }
  return <MuiChartContainerDefault {...props} />;
}

export function MuiChartContainerDefault(props: Readonly<ChartContainer>) {
  const offsetXRef = useRef(0);
  const [offsetX, setOffsetX] = React.useState<number>(offsetXRef.current);

  const { background, children, tooltip, sectionVisibility, ...chartProps } =
    props;

  // adjust the first X axis based on the offsetX value
  // only used for the bands
  React.useEffect(() => {
    setOffsetX(offsetXRef.current);
  }, [offsetXRef.current]);

  const dataset = props.dataset;
  const firstxAxis = props.xAxis?.at(0);
  const firstxAxisData =
    firstxAxis?.data ?? dataset?.map((d) => d[firstxAxis?.dataKey ?? '']);

  const firstSeries = props.series?.at(0);
  const firstSeriesData = firstSeries?.data;

  // if there is no data, return null
  if (!(dataset?.length || firstxAxisData?.length || firstSeriesData?.length)) {
    return null;
  }

  const bands = background?.bands;
  // bands needs to be added to the X axis with the scaleType set to linear
  if (bands && props.xAxis?.findIndex((e) => e.id === AXIS_BANDS_ID) === -1) {
    props.xAxis?.push({
      data: firstxAxisData ?? [],
      id: AXIS_BANDS_ID,
      scaleType: 'linear',
    });
  }

  const xAxisArray = getXAxisArray(props, offsetX);
  const yAxisArray = getYAxisArray(props);

  const firstxAxisId = firstxAxis?.id ?? 'x-axis-0';
  const maxLabelLength = getAxisLabelsMaxLength(firstxAxis, firstxAxisData);

  const angle = firstxAxis?.tickLabelStyle?.angle ?? 0;
  const chartBottomMargin =
    60 + maxLabelLength * 6 * Math.abs(Math.sin((Math.PI * angle) / 180));

  const tooltipSlots = getTooltipSlots(props);
  const chartMargins = props.margin;
  const margins = {
    left: chartMargins?.left ?? 80,
    right: chartMargins?.right ?? 30,
    bottom: chartMargins?.bottom ?? chartBottomMargin,
    top: chartMargins?.top ?? 10,
  };

  const showAxisX = sectionVisibility?.axisX !== false;
  const showAxisY = sectionVisibility?.axisY !== false;
  const showGrid = sectionVisibility?.grid !== false;
  const showHighlight = sectionVisibility?.highlight !== false;
  const showMarkers = sectionVisibility?.markers !== false;

  return (
    <StyledChartContainer
      height={props.height ?? 400}
      margin={margins}
      {...chartProps}
      yAxis={yAxisArray}
      xAxis={xAxisArray}
      data-testid='styled-chart-container'
    >
      {bands && <DrawBackground offsetX={offsetXRef} bands={bands} />}
      {showGrid && <ChartsGrid horizontal />}
      {showHighlight && <ChartsAxisHighlight x='line' />}
      {children}

      <BarPlot />
      <LinePlot />
      <ScatterPlot />
      {showMarkers && <MarkPlot />}
      {showAxisX && <ChartsXAxis axisId={firstxAxisId} />}
      {showAxisY && <ChartsYAxis disableTicks />}

      <StyledChartsTooltip trigger='item' slots={tooltipSlots} />
    </StyledChartContainer>
  );
}
