import { ApolloError } from '@apollo/client';
import { Box, Link, Skeleton, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import {
  DataGridProProps,
  GridApiPro,
  GridCellParams,
  GridColDef,
  GridPinnedPosition,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowSpacingParams,
  GridSingleSelectColDef,
  GridToolbar,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { CellHeader } from 'routes/vessel-data-monitor/components/fleetDataComparison/table/util/fleet-data-comparison-table.util';
import { useCallback, useContext, useEffect } from 'react';
import { ExcelExportContext } from 'shared/models/excel-export.context.model';
import { formatNumber, isNumeric } from 'shared/utils/float-utils';
import { ErrorComponent } from '../error/error.component';
import { RAGDisplay } from '../status-card/ragIndicator.component';
import { StripedDataGrid, columnHeaderProps } from './styles/ThemeProperties';
import { sectionContext } from '../section/section.component';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { Maybe } from '_gql/graphql';

function renderHeaderNewline(column: GridColumnDef) {
  const headerName = column.headerName ?? '';
  const lines = headerName.split('\n');
  return () => (
    <Tooltip title={column.description}>
      <Box className='MuiDataGrid-columnHeaderTitle' sx={{ width: '100%' }}>
        {lines.map((line, ix) => {
          const styleProperties: SxProps = { fontSize: 'inherit' };
          styleProperties.textAlign = column.headerAlign;

          return (
            <Typography key={ix} sx={styleProperties}>
              {line.trim()}
            </Typography>
          );
        })}
      </Box>
    </Tooltip>
  );
}

function processColumnsDefinition(
  columnsDefinition: readonly GridColumnDef[],
  tableData: any[],
  autoWidth: boolean
) {
  const hideColumns: { [key: string]: boolean } = {};

  columnsDefinition.forEach((x) => {
    if (x.headerName?.includes('\n') && !x.renderHeader) {
      x.renderHeader = renderHeaderNewline(x);
    }

    if (!x.headerName) {
      x.headerName = formatHeader(x.field);
    }

    if (x.visibility && typeof x.visibility === 'function') {
      const customFunction = x.visibility;
      const hasData = tableData
        .map((row) => customFunction(row))
        .find((x) => x === true);
      if (!hasData) hideColumns[x.field] = false;
    }

    if (x.visibility === 'hidden' || x.visibility === 'export-only') {
      hideColumns[x.field] = false; // seeting to false will hide the column
    }

    if (x.visibility === 'no-data-hide' && tableData.length > 0) {
      const hasData = tableData.find(
        (row) =>
          ![NO_VALUE_INDICATOR, NA_VALUE_INDICATOR].includes(
            objvalue(row, x.field)?.value ?? objvalue(tableData[0], x.field)
          )
      );
      if (!hasData) hideColumns[x.field] = false; // seeting to false will hide the column
    }

    // format decimal values
    if (
      typeof x.headerClassName === 'string' &&
      x.headerClassName.includes('rag-indicator')
    ) {
      tableData.forEach((row) => {
        const rowvalue = row[x.field];
        if (typeof rowvalue === 'object') {
          const isNumber = isNumeric(rowvalue.value);
          if (isNumber) {
            const decimals = Number(rowvalue.value) !== 0 ? x.noDecimals : 0;
            rowvalue.value = Number(rowvalue.value).toFixed(decimals);
          }
        }
      });
    }

    const columnType: string = x.type ?? 'string';
    if (
      columnType === 'singleSelect' &&
      (x as GridSingleSelectColDef).valueOptions === undefined
    ) {
      let uniqueValues = [];
      uniqueValues = Array.from(
        new Set(tableData.map((row) => objvalue(row, x.field)))
      );
      if (uniqueValues.length > 0 && uniqueValues.length < 10) {
        x.type = columnType;
        (x as GridSingleSelectColDef).valueOptions = uniqueValues;
      }
    }
    if (autoWidth && x.flex !== 0) {
      x.flex = 1;
    }
  });

  return { columns: columnsDefinition, hideColumns: hideColumns };
}

function getColumnDefinition(
  tableData: any[],
  columnsDefinition?: readonly GridColumnDef[],
  autoWidth = true
) {
  const keys = Object.keys(Object.assign({}, ...tableData));
  const hideColumns: { [key: string]: boolean } = {};

  // add the field 'id' if it doesn't exists
  if (!keys.includes('id')) {
    keys.unshift('id');
    tableData.forEach((item, i) => {
      item.id = i + 1;
    });
  }

  if (columnsDefinition) {
    return processColumnsDefinition(columnsDefinition, tableData, autoWidth);
  }

  const res: GridColumnDef[] = [];

  if (tableData.length > 0)
    keys.forEach((key) => {
      const firstColumnType = typeof tableData[0][key];
      let columnType: string = tableData.every(
        (x) => typeof x[key] === firstColumnType
      )
        ? firstColumnType
        : 'string';
      let uniqueValues = [];

      if (columnType === 'string') {
        uniqueValues = Array.from(
          new Set(tableData.map((x) => objvalue(x, key)))
        );
        if (uniqueValues.length < 10) {
          columnType = 'singleSelect';
        }
      }

      const colDef = {
        field: key,
        headerName: formatHeader(key),
        type: columnType,
        valueOptions: uniqueValues,
      };

      res.push(colDef);
    });
  return { columns: res, hideColumns: hideColumns };
}

const getHeaderHeight = (
  columnsDefinition?: readonly GridColumnDef[]
): number => {
  const headerHeight = 50;

  if (!columnsDefinition) return headerHeight;

  const newLinesExp = /\n/g;
  const maxNoLines =
    Math.max(
      ...columnsDefinition.map(
        (x) => x.headerName?.match(newLinesExp)?.length ?? 0
      )
    ) + 1;

  const fontSizeFromTheme = columnHeaderProps.fontSize?.toString() || '14px';
  const textHeight = parseInt(fontSizeFromTheme, 10) * 1.5;
  return Math.max(headerHeight, textHeight * maxNoLines + 10);
};

export type RowStyle = 'rounded' | 'none';
export type VisibilityOptions =
  | 'visible'
  | 'hidden'
  | 'no-data-hide'
  | 'export-only'
  | 'screen-only';

export type GridColumnDef = GridColDef & {
  valueField?: string;
  statusField?: string;
  noDecimals?: number;
  isNACheck?: (params: GridCellParams) => boolean;
  visibility?: VisibilityOptions | ((params: GridCellParams) => boolean);
  pinPosition?: GridPinnedPosition;
};

export interface DataGridTableProps extends Omit<DataGridProProps, 'columns'> {
  height?: number | string;
  columns?: readonly GridColumnDef[];
  autoWidth?: boolean;
  name?: string;
  error?: ApolloError | null | undefined;
  visible?: boolean;
  rowStyle?: RowStyle;
}

const CustomNoRowsOverlay = ({
  error,
  empty,
}: {
  error: ApolloError | null | undefined;
  empty: boolean;
}) => {
  return (
    <ErrorComponent
      data-testid={'error-component'}
      error={error}
      empty={empty}
      heightOverride='100%'
    />
  );
};

const LoadingSkeleton = (props: DataGridTableProps) => {
  const rowHeight = props.rowHeight ?? 52;
  const realHeight = props.getRowSpacing ? rowHeight : rowHeight / 2;
  const marginBottom = props.getRowSpacing ? '10px' : 3;

  const gridHeight = Math.max(
    props.apiRef?.current?.rootElementRef?.current?.clientHeight ?? 400,
    400
  );
  const noRows = Math.trunc(gridHeight / rowHeight) - 1;

  return (
    <Box data-testid='loading-component' sx={{ pt: 0 }}>
      {[...Array(noRows)].map((_, ix) => (
        <Skeleton
          key={`row-${ix}`}
          variant='rounded'
          sx={{ mb: marginBottom, mx: 1, height: realHeight }}
        />
      ))}
    </Box>
  );
};

const defaultGridBoxStyle: SxProps = { width: '100%' };
const hiddenGridBoxStyle: SxProps = {
  width: 100,
  visibility: 'hidden',
  position: 'absolute',
  top: 0,
};

export const DataGridTable = (props: DataGridTableProps) => {
  const initialGridState = { ...props.initialState };
  const data = [...props.rows].map((item) => ({ ...item })); // deep copy
  const columnsDefinition = getColumnDefinition(
    data,
    props.columns,
    props.autoWidth
  );
  const columns = columnsDefinition.columns;
  const headerHeight =
    props.columnHeaderHeight ?? getHeaderHeight(props.columns);
  const newGridRef = useGridApiRef();
  const apiRef = props.apiRef ?? newGridRef;
  const section = useContext(sectionContext);

  const hasData = data?.length > 0;

  if (initialGridState.pinnedColumns == null) {
    columns.forEach((column) => {
      if (column.pinPosition == null) {
        return;
      }

      initialGridState.pinnedColumns ??= {};
      initialGridState.pinnedColumns[column.pinPosition] ??= [];
      initialGridState.pinnedColumns[column.pinPosition]?.push(column.field);
    });
  }

  const setExcelData = () => {
    if (props.name) {
      setFilteredData({
        name: props.name,
        sectionId: section,
        apiRef: apiRef,
      });
    }
  };

  const { setFilteredData } = useContext(ExcelExportContext);
  useEffect(() => {
    setExcelData();
  }, [apiRef]);

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const customProps = { ...props };
  if (props.rowStyle === 'rounded') {
    customProps.getRowSpacing = getRowSpacing;
    customProps.getRowClassName = () => 'rounded';
  }

  const gridBoxSxProps =
    props.visible === false ? hiddenGridBoxStyle : defaultGridBoxStyle;

  return (
    <Box sx={{ ...gridBoxSxProps, height: props.height ?? 400 }}>
      <StripedDataGrid
        {...customProps}
        apiRef={apiRef}
        rows={data}
        columns={[...columns]}
        initialState={initialGridState}
        columnHeaderHeight={headerHeight}
        hideFooter={props.hideFooter ?? true}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnReorder
        disableColumnResize
        disableRowSelectionOnClick
        disableMultipleColumnsSorting
        disableColumnMenu={true}
        getRowClassName={customProps.getRowClassName ?? defaultRowClassName}
        slots={{
          ...props.slots,
          noRowsOverlay: () =>
            CustomNoRowsOverlay({ error: props.error, empty: !hasData }),
          loadingOverlay: () => LoadingSkeleton(props),
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: props.slotProps?.toolbar?.showQuickFilter ?? false,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        columnVisibilityModel={columnsDefinition.hideColumns}
      />
    </Box>
  );
};

export type RemoveOptionalFields<Type> = {
  [Property in keyof Type]-?: Type[Property];
};

type IsUnion<T, U extends T = T> = T extends unknown
  ? [U] extends [T]
    ? false
    : true
  : false;

export type RemoveComplexFields<Type> = {
  [Property in keyof Type]: IsUnion<Type[Property]> extends false
    ? Type[Property] extends UTCDate | Array<any> | null
      ? true
      : Type[Property]
    : false;
};

type RemoveMaybe<Obj> = {
  [Prop in keyof Obj]: Obj[Prop] extends Maybe<infer T> ? T : false;
};

export type NestedKeyOf<T> = T extends object
  ? {
      [K in keyof T]: T[K] extends null
        ? `${Exclude<K, symbol>}`
        : `${Exclude<K, symbol>}${
            | ''
            | `.${NestedKeyOf<RemoveComplexFields<T[K]>>}`}`;
    }[keyof T]
  : never;

export type CleanObject<T> = RemoveComplexFields<
  RemoveOptionalFields<RemoveMaybe<T>>
>;

export function nameof<T extends object>(
  attributes: NestedKeyOf<CleanObject<T>>
): string {
  return attributes;
}

export const RenderCellRAG = (params: GridRenderCellParams<any>) => {
  const base = params.colDef as GridColumnDef;
  const noDecimals = base.noDecimals ?? 0;
  const valueField = base.valueField ?? base.field + '.value';
  const statusField = base.statusField ?? base.field + '.status';
  const rawValue = objvalue(params.row, valueField);

  const value = formatNumber(rawValue, noDecimals);
  const status = objvalue(params.row, statusField);
  return <RAGDisplay value={value} status={status} />;
};

const renderCellRAGLink = (params: any, navigateTo?: any) => {
  const base = params.colDef as GridColumnDef;
  const noDecimals = base.noDecimals ?? 0;
  const valueField = base.valueField ?? base.field + '.value';
  const statusField = base.statusField ?? base.field + '.status';
  const rawValue = objvalue(params.row, valueField);

  const value = formatNumber(rawValue, noDecimals);
  const status = objvalue(params.row, statusField);
  return (
    <Link onClick={() => navigateTo(params.row)}>
      <RAGDisplay value={value} status={status} />
    </Link>
  );
};

const defaultValueFormatterNumber = (
  params: GridValueFormatterParams<any>,
  noDecimals?: number
) => {
  const rawValue = params.value;
  const value = formatNumber(rawValue, noDecimals);
  return value;
};

const defaultValueFormatter = (
  params: GridValueFormatterParams<any>,
  base?: GridColumnDef
) => {
  if (base?.isNACheck && params?.id) {
    const row = params?.api.getRow(params.id);
    const isNA = base.isNACheck({ row: row } as GridCellParams);
    if (isNA) return NA_VALUE_INDICATOR;
  }

  if (base?.type === 'number')
    return defaultValueFormatterNumber(params, base.noDecimals);

  return params.value ?? NO_VALUE_INDICATOR;
};

export const NA_VALUE_INDICATOR = '---';
export const NO_VALUE_INDICATOR = '---';
export const objvalueRaw = (obj: any, path: string, noValue: any) =>
  path.split('.').reduce((o, i) => o?.[i] ?? noValue, obj);

export const objvalue = (obj: any, path: string) =>
  objvalueRaw(obj, path, NO_VALUE_INDICATOR);

const defaultValueGetter = (params: GridValueGetterParams) => {
  const base = params.colDef as GridColumnDef;
  const isNA = base.isNACheck ? base.isNACheck(params) : false;
  if (isNA) return undefined;

  const isRAG =
    typeof base.headerClassName === 'string' &&
    base.headerClassName?.includes('rag-indicator');

  const defaultValueField = isRAG ? base.field + '.value' : base.field;
  const valueField = base.valueField ?? defaultValueField;

  const rawValue = objvalueRaw(params.row, valueField, undefined);
  const isNumber = isNumeric(rawValue);
  const defaultValue = base.type === 'number' ? undefined : rawValue;
  return isNumber ? Number(rawValue) : defaultValue;
};

export function GridColumn(base: GridColumnDef): GridColumnDef {
  const newSettings: GridColumnDef = {
    ...base,
    type: base.type ?? 'number',
    noDecimals: base.noDecimals ?? 0,
    align: base.align ?? 'center',
    headerAlign: base.headerAlign ?? 'center',
    valueGetter: base.valueGetter ?? defaultValueGetter,
  };

  if (!base.valueFormatter)
    newSettings.valueFormatter = (params) =>
      defaultValueFormatter(params, newSettings);

  return newSettings;
}

const defaultRowClassName = (params: GridRowClassNameParams) =>
  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';

export function GridRAGColumn(base: GridColumnDef): GridColumnDef {
  return GridColumn({
    ...base,
    renderCell: base.renderCell ?? RenderCellRAG,
    headerClassName: ((base.headerClassName ?? '') + ' rag-indicator').trim(),
  });
}

export function GridRAGColumnLink(
  base: GridColumnDef,
  navigateTo: any
): GridColumnDef {
  return GridColumn({
    ...base,
    renderCell: (params) => renderCellRAGLink(params, navigateTo),
    headerClassName: ((base.headerClassName ?? '') + ' rag-indicator').trim(),
  });
}

const formatHeader = (header: string) =>
  header
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

const getExcelHeaders = (gridColumns: readonly GridColumnDef[]) => {
  const result = gridColumns.map((x, ix) => {
    return {
      id: ix,
      label: x.headerName ?? formatHeader(x.field),
    } as CellHeader;
  });

  return result;
};

function getValue(gridRef: GridApiPro, row: any, column: GridColumnWithLabels) {
  let rowValue = objvalue(row, column.label);

  if (column.valueFormatter) {
    const rawValue = objvalueRaw(row, column.label, undefined);
    const params = {
      id: row.id,
      field: column.label,
      value: rawValue,
    } as GridValueFormatterParams<any>;
    params.api = gridRef;
    rowValue = column.valueFormatter(params);
  }

  const isRag = column.isRag && typeof rowValue !== 'object'; // check if column is defined as RAG, and also check if rowValue has the required RAG structure
  if (isRag)
    return {
      value: rowValue,
      status: objvalue(row, column.statusField ?? '')?.toLowerCase(),
    };

  return rowValue;
}

type GridColumnWithLabels = GridColumnDef & {
  label: string;
  isRag: boolean;
};

export function getExcelData(gridRef: GridApiPro) {
  const allColumns: GridColumnDef[] = gridRef.getAllColumns();
  const visibleColumnsField = gridRef.getVisibleColumns().map((x) => x.field);
  const hiddenColumnsField = new Set(
    allColumns
      .filter(
        (x) =>
          (x.visibility !== 'export-only' &&
            !visibleColumnsField.includes(x.field)) ||
          x.visibility === 'screen-only'
      )
      .map((x) => x.field)
  );
  const columns = allColumns.filter((x) => !hiddenColumnsField.has(x.field));

  const headers = getExcelHeaders(columns);
  const data = [...gridRef.getSortedRows()];

  const fieldNames: GridColumnWithLabels[] = columns.map((x) => {
    return {
      ...x,
      label: x.field,
      statusField:
        x.statusField ??
        x.field.replace(x.field.split('.').pop() ?? '', 'status'),
      isRag:
        typeof x.headerClassName === 'string' &&
        x.headerClassName?.includes('rag-indicator'),
    } as GridColumnWithLabels;
  });
  const sortedData: any[] = [];

  data.forEach((row) => {
    const sortedRow: { [k: string]: any } = {};
    fieldNames.forEach((column) => {
      const value = getValue(gridRef, row, column);
      sortedRow[column.label] = value;
    });
    sortedData.push(sortedRow);
  });

  const ragColumns = columns.map(
    (x) =>
      typeof x.headerClassName === 'string' &&
      x.headerClassName?.includes('rag-indicator')
  );
  const ragColumnsIndexes = ragColumns
    .map((x, ix) => (x ? ix + 1 : -1))
    .filter((ix) => ix !== -1);

  return {
    data: sortedData,
    rawData: data,
    headers: headers,
    colorColumnIndexes: ragColumnsIndexes,
  };
}
