import CircleIcon from '@mui/icons-material/Circle';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'styles/theme';
import {
  EuaChartData,
  xAxisTickFormatter,
  yAxisTitle,
} from '../../../view-models/eua-ratings-chart.viewModel';
import { TEXT } from 'shared/constants/text';
import { formatNumber } from 'shared/utils/float-utils';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { UTCDate } from 'shared/utils/date-utc-helper';

export const EuaChart = (props: { chartData: EuaChartData }) => {
  const planned = props.chartData.planned ?? 0;
  const data = props.chartData.data ?? undefined;
  const yMax = props.chartData.yMax ?? 0;
  return (
    <ResponsiveContainer height={415} width='99%'>
      <ComposedChart
        data={data}
        margin={{ top: 20, bottom: 60, right: 25, left: 25 }}
      >
        <XAxis
          dataKey='key'
          angle={-35}
          tick={{ fill: theme.colors?.offWhite }}
          type='category'
          textAnchor='end'
          tickFormatter={xAxisTickFormatter}
          fontSize={12}
          padding={{ left: 8, right: 0 }}
        />
        <YAxis
          allowDataOverflow
          fontSize={12}
          dataKey='value'
          tickLine={false}
          tick={{ fill: theme.colors?.offWhite }}
          tickMargin={10}
          domain={[0, yMax]}
          type='number'
          axisLine={false}
          dx={-12}
          width={75}
          tickCount={20}
        >
          <Label
            fontSize={12}
            fill={theme.palette.text.primary}
            position={'insideLeft'}
            offset={-5}
            angle={-90}
            value={yAxisTitle}
          />
        </YAxis>
        <CartesianGrid
          color={theme.colors.white}
          vertical={false}
          strokeDasharray='0'
          opacity={0.14}
        />
        <Tooltip content={CustomTooltip} wrapperStyle={{ zIndex: 10000 }} />
        <Bar
          dataKey='inOutBarItem'
          stackId='1'
          fill={theme.colors?.cyan}
          id='aarea'
          name='Arm1'
          barSize={30}
        />
        <Bar
          dataKey='withinBarItem'
          stackId='1'
          fill={theme.vesselPerformance.charts.secondaryPurple}
          id='barea'
          name='Arm2'
          cursor={'pointer'}
          barSize={30}
        />
        <Line
          type='linear'
          activeDot={true}
          dot={true}
          dataKey='ytdTotal'
          stroke={theme.colors?.aRating}
          strokeWidth={2}
          connectNulls={true}
        />
        {planned !== 0 && (
          <ReferenceLine
            y={planned}
            strokeWidth={2}
            stroke={theme.colors.magenta}
            dx='color:blue'
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
const divStyle = {
  color: 'black',
  background: '#EFEFF0',
  borderRadius: '4px',
  padding: '8px',
  font: '10px',
};
export const CustomTooltip: any = function (that: any): any {
  if (that.active && that.payload && that.payload.length) {
    const monthString = new UTCDate(that.label).formatM();
    const payloadObj = that.payload[0].payload;
    const inOutPerc = (
      ((payloadObj['inOutBarItem'] | 0) / (payloadObj['value'] | 1)) *
      100
    ).toFixed(0);
    const monthHasData = payloadObj['monthHasData'];
    const previousMonthHasData = payloadObj['previousMonthHasData'];
    const withinPerc = (
      ((payloadObj['withinBarItem'] | 0) / (payloadObj['value'] | 1)) *
      100
    ).toFixed(0);
    const elem = (
      <div style={divStyle}>
        {payloadObj['planned'] > 0 && (
          <div>
            <CircleIcon
              sx={{ m: 0, fontSize: '12px', color: theme.colors.magenta }}
            />
            <span className=''>
              {' '}
              Planned EUA: {formatNumber(payloadObj['planned'])}
            </span>
          </div>
        )}
        <div>
          <CircleIcon
            sx={{ m: 0, fontSize: '12px', color: theme.colors?.aRating }}
          />
          <span className=''>
            {' '}
            YTD EUA Total:{' '}
            {monthHasData || previousMonthHasData
              ? formatNumber(payloadObj['ytdTotal'])
              : TEXT.ERROR_MESSAGES.EMPTY_DASHES}
          </span>
        </div>
        <div>
          <CircleIcon
            sx={{
              m: 0,
              fontSize: '12px',
              color: theme.vesselPerformance.charts.secondaryPurple,
            }}
          />
          <span className=''>
            {' '}
            EUA Within:{' '}
            {monthHasData
              ? payloadObj['withinBarItem']
                ? `${formatNumber(
                    payloadObj['withinBarItem']
                  )} (${withinPerc}%)`
                : NA_VALUE_INDICATOR
              : TEXT.ERROR_MESSAGES.EMPTY_DASHES}
          </span>
        </div>
        <div>
          <CircleIcon
            sx={{ m: 0, fontSize: '12px', color: theme.colors?.cyan }}
          />
          <span className=''>
            {' '}
            EUA In/Out:{' '}
            {monthHasData
              ? payloadObj['inOutBarItem']
                ? `${formatNumber(payloadObj['inOutBarItem'])} (${inOutPerc}%)`
                : NA_VALUE_INDICATOR
              : TEXT.ERROR_MESSAGES.EMPTY_DASHES}
          </span>
        </div>
        <CircleIcon
          sx={{
            m: 0,
            fontSize: '12px',
            color: theme.vesselPerformance.charts.toolTipBackground,
          }}
        />
        <span className=''>
          {' '}
          {monthString} EUA Total:{' '}
          {monthHasData
            ? formatNumber(payloadObj['value'])
            : TEXT.ERROR_MESSAGES.EMPTY_DASHES}
        </span>
      </div>
    );
    return elem;
  }
  return null;
};
